<main class="account edit-page only-back">
    <header>
        <a href="#" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a [routerLink]="['/']" class="back-link"><span>&nbsp;</span></a>
        </div>
        <div class="logout-wrapper">
            <a (click)="logout()" href="#" class="logout-link"><span>Выйти</span></a>
        </div>
    </header>
    <section class="account-page">
        <div [ngClass]="{'subscribe-info': true, 'loading': loading }">
            <h2>Подписка</h2>
            <ul class="info-list">
                <ng-container *ngIf="!loading">
                <li class="subscription-name"><p>{{ planTitle }}</p></li>
                <li *ngIf="isPremium" class="with-icon included-options all"><p>Доступен весь функционал</p></li>
                <li *ngIf="!isPremium" class="with-icon included-options limited"><p>Ограниченный доступ к функционалу</p></li>
                <li class="with-icon price"><p>{{ planPrice }}</p></li>
                <li *ngIf="activeTill" class="with-icon expiration"><p>{{ activeTill }}</p></li>
                </ng-container>
            </ul>
            
            <ng-container *ngIf="!inApp">
                <div *ngIf="isTrial || !isPremium" class="actions-wrapper">
                    <a [routerLink]="['/account/subscribe']" class="btn-black"><span>Оформить подписку</span></a>
                    <p><a href="#" class="continue-whithout-sbp">Продолжить без подписки</a></p>
                </div>
                <div class="card-subscription-actions">
                    <div *ngIf="isPremium && !isTrial" class="card-info">
                        <p class="payment-heading">Способ оплаты</p>
                        <p class="card-num">{{ paymentInfo }}</p>
                    </div>
                    <div *ngIf="isPremium && !isTrial">
                        <p class="action"><a [routerLink]="['/account/subscribe']" class="btn-white change-subscription"><span>Сменить план</span></a></p>
                        <p *ngIf="activeSubscription" class="action"><a href="#" (click)="cancelSubscription()" class="btn-white cancel-subscription"><span>Отменить подписку</span></a></p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!loading && (isTrial || !isPremium)">
            <table class="options-list">
                <tr>
                    <td></td>
                    <td><p>Бесплатно</p></td>
                    <td><p class="dark-label">Подписка</p></td>
                </tr>
                <tr>
                    <td class="with-icon cycle-track">
                        <p class="service-name">Цикл-трекер</p>
                        <p class="service-options">Комфортный интерфейс для отслеживания циклов</p>
                    </td>
                    <td><span class="include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon statistic">
                        <p class="service-name">Статистика</p>
                        <p class="service-options">Подробная информация о текущем дне фазы и полная статистика о цикле</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon food">
                        <p class="service-name">Питание</p>
                        <p class="service-options">Авторская программа питания с едемесячным обновлением полезных рецептов</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon activity">
                        <p class="service-name">Активность</p>
                        <p class="service-options">Индивидуально подобранная программа тренировок на каждую фазу цикла с ежемесячным обновлением</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon moon">
                        <p class="service-name">Лунный календарь</p>
                        <p class="service-options">Астрологические календарь на каждый день с подсказками по красоте и здоровью</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr >
                    <td class="with-icon blog">
                        <p class="service-name">Блог</p>
                        <p class="service-options">Статьи и советы от именитых экспертов и основателей wellness проектов</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
            </table>
        </div>
        
    </section>
    <div class="mobile-logout">
        <a (click)="logout()" href="#" class="logout-link"><span>Выйти</span></a>
    </div>
    <footer>
        <div *ngIf="inApp" class="subscription-links if-app-subscription">
            <p>Подписка была оформлена через приложение  Ēastragene. Для управление подпиской перейдите в него</p>

            <a class="download-to-phone" href="javasript:;"><span class="apple-icon">&nbsp;</span><span>Перейти в приложение</span><img class="q-icon" src="../assets/images/q-code.svg" ></a>

        </div>
        <app-footer />
    </footer>
</main>
